import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel, InputAdornment, OutlinedInput, FormControlLabel, Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import copyObject from "../utils/copyObject";
import Paper from "@mui/material/Paper";
import {
    useSessionResultsAtTheMomentOfPassingByChairWebReportMutation
} from "../store/reports/reportsApi";
import {useListAllChairsQuery} from "../store/chair/chairApi";
import {Alert, AlertTitle} from "@mui/lab";

export default function SessionResultsAtTheMomentOfPassingByChairWebReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data: chairsData} = useListAllChairsQuery();
    const [dataRequest] = useSessionResultsAtTheMomentOfPassingByChairWebReportMutation();
    const [data, setData] = useState([]);
    const [chairs, setChairs] = useState([]);
    const minYear = 2021; // temp limit

    useEffect(() => {
        if (!chairsData)
            return;

        setChairs(copyObject(chairsData.chairs))
    }, [chairsData]);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const loadData = async (chairId) => {
        setIsSaving(true);

        const request = {
            chairId: chairId === 0 ? null : chairId
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.chairs))
            } else {
                fulfilled.errors?.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };
    
    function avgOverYear(year, prop) {
        if (year.sessions.length === 0)
            return 0;
        
        let sum = 0;
        year.sessions.forEach((item) => {
            sum += item[prop];
        })
        
        return (sum / year.sessions.length);
    }
    
    function sum(year, prop) {
        let sum = 0;
        year.sessions.forEach((item) => {
            sum += item[prop];
        })
        
        return sum;
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Результаты сессии на момент ее сдачи (По кафедрам)`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress/> : <></>}
                            Результаты сессии на момент ее сдачи (По кафедрам)
                        </Typography>
                    </Stack>
                    <Alert color={'warning'}>
                        Временно установлен минимальный год для вывода данных - {minYear}
                    </Alert>

                    <Box>
                        {/* Фильтр */}
                        <Card>
                            <CardContent>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size={'small'}>Кафедра</InputLabel>
                                    <Select size={'small'}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Кафедра"
                                            onChange={(event) => {
                                                loadData(parseInt(event.target.value, 10))
                                            }}
                                    >
                                        <MenuItem key={0} value={0}>Все</MenuItem>
                                        {chairs?.sort((l, r) => l.value > r.value).map((chair) => {
                                            return <MenuItem key={chair.value}
                                                             value={chair.key}>{chair.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </CardContent>
                        </Card>

                        <br/>

                        {/* Таблицы */}
                        {data?.sort((l,r) => l.chairName > r.chairName ? 1 : -1).map((chair, index) => {
                            return <Box mb={4}>
                                <Typography variant="h6" gutterBottom>
                                    Кафедра {chair.chairName}
                                </Typography>

                                <Card>
                                    <TableContainer>
                                        <Table size={'small'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell rowSpan={2}>Учебный год</TableCell>
                                                    <TableCell rowSpan={2}>Сессия</TableCell>
                                                    <TableCell rowSpan={2} align={'center'}>Всего человекодисциплин</TableCell>
                                                    <TableCell colSpan={2} align={'center'}>"5"</TableCell>
                                                    <TableCell colSpan={2} align={'center'}>"4"</TableCell>
                                                    <TableCell colSpan={2} align={'center'}>"3"</TableCell>
                                                    <TableCell colSpan={2} align={'center'}>"2"</TableCell>
                                                    <TableCell colSpan={2} align={'center'}>"н/я"</TableCell>
                                                    <TableCell colSpan={2} align={'center'}>"н/д"</TableCell>
                                                    <TableCell rowSpan={2}>Ср. балл</TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {chair.years.filter((item) => parseInt(item.yearName.slice(0,4),10) >= minYear).sort((l,r) => l.yearName > r.yearName ? 1 : -1).map((year) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell rowSpan={year.sessions.length + 1}>{year.yearName}</TableCell>
                                                        </TableRow>
                                                        {year.sessions.map((session, index) => {
                                                            const isTotalRow = index + 1 === year.sessions.length;
                                                            return <TableRow style={{"backgroundColor": isTotalRow ? '#F0F0F0' : '#FFFFFF'}}>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.sessionName}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}} align={'center'}>{session.numberOfPersonDisciplines}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.quantityOfGrade5}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.percentageOfGrade5.toFixed(2)}%</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.quantityOfGrade4}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.percentageOfGrade4.toFixed(2)}%</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.quantityOfGrade3}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.percentageOfGrade3.toFixed(2)}%</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.quantityOfGrade2}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.percentageOfGrade2.toFixed(2)}%</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.quantityOfGradeNotAppeared}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.percentageOfGradeNotAppeared.toFixed(2)}%</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.quantityOfGradeNotAllowed}</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.percentageOfGradeNotAllowed.toFixed(2)}%</TableCell>
                                                                <TableCell style={isTotalRow ? {'fontWeight': 'bold'} : {}}>{session.totalAvgGrade.toFixed(2)}</TableCell>
                                                            </TableRow>
                                                        })}

                                                        {/* <TableRow style={{"backgroundColor": '#F0F0F0'}}> */}
                                                        {/*    <TableCell><strong>ИТОГО</strong></TableCell> */}
                                                        {/*    <TableCell>{sum(year, 'numberOfPersonDisciplines')}</TableCell>* /}
                                                        {/*    <TableCell>{sum(year, 'quantityOfGrade5')}</TableCell> */}
                                                        {/*    <TableCell>{avgOverYear(year, 'percentageOfGrade5').toFixed(2)}%</TableCell> */}
                                                        {/*    <TableCell>{sum(year, 'quantityOfGrade4')}</TableCell> */}
                                                        {/*    <TableCell>{avgOverYear(year, 'percentageOfGrade4').toFixed(2)}%</TableCell> */}
                                                        {/*    <TableCell>{sum(year, 'quantityOfGrade3')}</TableCell> */}
                                                        {/*    <TableCell>{avgOverYear(year, 'percentageOfGrade3').toFixed(2)}%</TableCell> */}
                                                        {/*    <TableCell>{sum(year, 'quantityOfGrade2')}</TableCell> */}
                                                        {/*    <TableCell>{avgOverYear(year, 'percentageOfGrade2').toFixed(2)}%</TableCell> */}
                                                        {/*    <TableCell>{sum(year, 'quantityOfGradeNotAllowed')}</TableCell> */}
                                                        {/*    <TableCell>{avgOverYear(year, 'percentageOfGradeNotAllowed').toFixed(2)}%</TableCell> */}
                                                        {/*    <TableCell>{sum(year, 'quantityOfGradeNotAppeared')}</TableCell> */}
                                                        {/*    <TableCell>{avgOverYear(year, 'percentageOfGradeNotAppeared').toFixed(2)}%</TableCell> */}
                                                        {/*    <TableCell>{avgOverYear(year, 'totalAvgGrade').toFixed(2)}</TableCell> */}
                                                        {/* </TableRow> */}
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Box>
                        })} 
                    </Box>

                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}