import {Helmet} from 'react-helmet-async';
import {filter} from 'lodash';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TableHead,
    CardContent,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Box,
    Tab,
    ButtonGroup,
    Tabs, CircularProgress,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
import JournalListRow from "../sections/@dashboard/journal/JournalListRow";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {
    useGetBindingsQuery,
    useGetEmployeeBindingsQuery,
    useGetFacultyBindingsQuery, useGetVirtualGroupsQuery, useRemoveVirtualGroupMutation
} from "../store/journal/journalApi";
import {useGetUserDetailsQuery} from "../store/user/userApi";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import TextField from "@mui/material/TextField";
import {Book, PanoramaFishEye, RemoveCircleOutline, RemoveRedEyeSharp} from "@mui/icons-material";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import AddIcon from "@mui/icons-material/Add";
import {store} from "../store/store";
// import {newstore} from "../store/index"
import CollapsibleRow from "./journalmain/components/collapsibleRow";
import ErrorBanner from "../components/error_banner";
import FilterCourses from "./journalmain/components/filter_courses";
import "./styles/journalmain.scss"
import MainLayout from "./main";
import PageHeader from "../components/pageHeader";
import PageTitle from "../components/PageTitle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// sections

// ----------------------------------------------------------------------

export default function JournalMainPage() {
    const navigate = useNavigate();
    const [bindings, setBindings] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Список курсов");
    const roles = localStorage.getItem("roles")?.split(',') ?? [];
    const isChair = roles.find((item) => item === "chair");
    const isEmployee = roles.find((item) => item === "employee");
    const isAdmin = roles.find((item) => item === "AttNewAdmin");
    const {data} = useGetBindingsQuery();
    const [copyOfData, setCopyOfData] = useState([]);
    const {data: userData} = useGetUserDetailsQuery();
    const {data: virtualGroupsData} = useGetVirtualGroupsQuery();
    const [tabIndex, setTabIndex] = useState("1");
    const [virtualGroups, setVirtualGroups] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [courses, setCourses] = useState([])
    const [cStates, setCStates] = useState([])
    const [globalCollapse, setGlobalCollapse] = useState(true)
    const [refresh, setRefresh] = useState(false);
    const [disciplines, setDisciplines] = useState([])
    const [coursesList, setCoursesList] = useState([])
    const [removeVirtualGroupMutation] = useRemoveVirtualGroupMutation();

    useEffect(() => {
        if (virtualGroupsData !== null && virtualGroupsData !== undefined) {
            console.log(`Virtual groups: `, virtualGroupsData.data)
            setVirtualGroups(copyObject(virtualGroupsData.data));
        }
        
        if (data === null || data === undefined)
            return;

        setCopyOfData(copyObject(data?.bindings))
        setBindings(copyObject(data?.bindings))
        
        const states = [];
        data.bindings.forEach(item => {
            states.push({courseId: item.courseId, disciplineId: item.disciplineId, isOpen: false})
        });

        setDisciplines(data.bindings.map((item) => item.disciplineName)
            .sort((left, right) => left > right ? 1 : -1));

        setCoursesList(data.bindings.map((item) => item.courseName)
            .sort((left, right) => left > right ? 1 : -1))

        setCStates(states);
    }, [data, virtualGroupsData]);
    
    function applyVisualChanges() {
        setRefresh(!refresh)
    }

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }

    function handleClick(groupFlowTeamId) {
        console.log(roles);
        if (roles.find((item) => item === 'employee') === undefined) {
            return;
        }

        navigate(`/journaledit/${groupFlowTeamId}/0`,
            {
                replace: false,
                state: {
                    groupFlowTeamId
                }
            });
    }

    function handleTabChange(event, val) {
        setTabIndex(val);
    }

    /**
     * Переключение открытости строки
     * @param isOpen открытость строки
     * @param id - идентификатор строки
     */
    const toggler = (isOpen, courseId, disciplineId) => {
        console.log('toggler', courseId, disciplineId)
        try {
            const states = [...cStates]
            const state = states.filter(c => {
                return c.courseId === courseId && c.disciplineId === disciplineId
            })
            state[0].isOpen = isOpen
            setCStates(states)
        } catch (ex) {
            console.warn(ex)
        }
    }

    /**
     * Переключение открытости всех строк
     */
    const toggleCollapse = () => {
        setGlobalCollapse(!globalCollapse)

        const states = [...cStates]
        states.forEach(c => {
            c.isOpen = globalCollapse
        })

        setCStates(states)
    }

    /**
     * Применение фильтров
     * @param value - Фильтры, переданные хуком
     */
    const handleApply = value => {
        console.log('search', value)
        setBindings(copyOfData?.filter((item) => (item.disciplineName === value.discipline || !value.discipline)
            && (item.courseName === value.course || !value.course)
            && (!value.search || item.disciplineName.toLowerCase().startsWith(value.search.toLowerCase()))))
    }

    /**
     * Переход на семестр, содержащий ошибки
     * @param semester
     */
    const errorCallback = semester => {
        // newstore.dispatch({ type: "write", payload: semester })
        navigate("/main/statements")
    }

    const switchTab = (e, newVal) => {
        setSelectedTab(newVal);
        console.log(newVal)
        console.log(selectedTab !== "Сводные группы")
        applyVisualChanges()
    };
    
    async function removeVirtualGroup(vgroup) {
        if (!isAdmin) {
            return;
        }
        
        // НЕ УДАЛЯТЬ КОММЕНТАРИЙ НИЖЕ! ЭТО ПРАВИЛО ESLINT
        // eslint-disable-next-line no-restricted-globals
        const agree = confirm(`Вы уверены, что хотите удалить сводную группу ${vgroup.virtualGroupName}? Убедитесь, что по сводной группе нет заполненных занятий`)
        
        if (agree) {
            await removeVirtualGroupMutation({
                data: {
                    virtualGroupId: vgroup.virtualGroupId
                }
            }).unwrap()
                .then((fulfilled) => {
                    if (fulfilled?.success) {
                        vgroup.deleted = true;
                        applyVisualChanges();
                    } else {
                        fulfilled.errors.map((item) => {
                            alert(item);
                            return 1;
                        });
                    }
                }).catch((reason) => {
                    alert("Ошибка");
                });
        }
    }

    return (
        <>
            <MainLayout>
                <PageTitle title={'Список курсов'} />
                <main>
                    <section>
                        <div className="container">
                            {/* <ErrorBanner */}
                            {/*    message={message} */}
                            {/*    errorsTitle={title} */}
                            {/*    errors={errors} */}
                            {/*    errorCallback={errorCallback} */}
                            {/* /> */}
                            <PageHeader header={selectedTab}/>
                            <Card>
                                <CardContent >
                                    <TabContext value={selectedTab}>
                                        <TabList
                                            onChange={switchTab}
                                            variant="scrollable"
                                            sx={{marginBottom: "20px"}}
                                        >
                                            <Tab
                                                value="Список курсов"
                                                label="Список курсов"
                                            />
                                            {userData?.userFlags?.virtualGroupEnabled
                                            ? <Tab
                                                    value="Сводные группы"
                                                    label="Сводные группы"
                                                />
                                            : <></>}
                                        </TabList>

                                        <TabPanel value={'Список курсов'} style={{'padding': '0px'}}>
                                            <div className="courses-filters">
                                                <FilterCourses
                                                    disciplines={disciplines}
                                                    courses={coursesList}
                                                    handleApply={handleApply}
                                                />
                                                <div className="toggler-wrapper">
                                                    <Button
                                                        variant="outlined"
                                                        sx={{fontFamily: "Wix Madefor Text"}}
                                                        onClick={toggleCollapse}
                                                    >
                                                        {globalCollapse ? "Развернуть" : "Свернуть"} все дисциплины
                                                    </Button>
                                                </div>
                                            </div>
                                            <TableContainer>
                                                <Table
                                                    sx={{
                                                        minWidth: 650,
                                                        borderSpacing: 0,
                                                        borderCollapse: "collapse"
                                                    }}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: "47%"}} colSpan={2}>
                                                                Дисциплины
                                                            </TableCell>
                                                            <TableCell sx={{width: "12%"}}>Лекции</TableCell>
                                                            <TableCell sx={{width: "12%"}}>Семинары</TableCell>
                                                            <TableCell sx={{width: "12%"}}>Лаб. занятия</TableCell>
                                                            <TableCell sx={{width: "5%", textAlign: "right"}}>
                                                                Действия
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {bindings?.map((row, index) => {
                                                            return (
                                                                <CollapsibleRow
                                                                    row={row}
                                                                    key={index}
                                                                    isOpen={cStates[index]?.isOpen}
                                                                    toggler={toggler}
                                                                    index={index}
                                                                />
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>
                                        
                                        <TabPanel value={'Сводные группы'}>
                                            <Stack spacing={2} padding={'0px'}>
                                                <Stack direction={"row"}
                                                       spacing={1}
                                                       alignItems={"center"}
                                                       justifyContent={"space-between"}>
                                                    {isEmployee
                                                        ? <Button startIcon={<AddIcon />}
                                                                  variant={"outlined"}
                                                                  href={`/create-virtual-group`}>Создать сводную группу</Button>
                                                        : <></>
                                                    }
                                                    <Stack direction={"row"} spacing={1}>
                                                        <TextField size={"small"}
                                                                   placeholder={"Наименование сводной группы"}
                                                                   onInput={(event) => setNameFilter(event.target.value)}
                                                                   value={nameFilter}/>
                                                    </Stack>
                                                </Stack>

                                                {virtualGroupsData === null || virtualGroupsData === undefined
                                                ? <><CircularProgress /></>
                                                : <></>}
                                                {virtualGroups?.map((vg) => {
                                                    return <>
                                                        <Typography variant={"h5"}>{vg.disciplineName}</Typography>
                                                        <Table size={"small"}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>#</TableCell>
                                                                    <TableCell>Название группы</TableCell>
                                                                    <TableCell>Преподаватель</TableCell>
                                                                    <TableCell>Кол-во часов</TableCell>
                                                                    <TableCell>Операции</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {vg.virtualGroups.filter((item) => item.virtualGroupName.toLowerCase().startsWith(nameFilter.toLowerCase()))
                                                                    .map((item, index) => {
                                                                        return <TableRow>
                                                                            <TableCell>{index + 1}</TableCell>
                                                                            <TableCell>
                                                                                {item.virtualGroupName}
                                                                                {item.deleted
                                                                                ? <Typography color={'error'}>Удалена</Typography>
                                                                                : <></>}
                                                                            </TableCell>
                                                                            <TableCell>{item.employeeFullName}</TableCell>
                                                                            <TableCell>{item.filledHours} из {item.totalHours}</TableCell>
                                                                            <TableCell>
                                                                                <ButtonGroup size={'small'}>
                                                                                    <Button startIcon={<RemoveRedEyeSharp />}
                                                                                            href={`/journaledit/0/${item.virtualGroupId}`}>Начать перекличку</Button>
                                                                                    <Button startIcon={<AutoStoriesOutlinedIcon />}
                                                                                            href={`/virtual-group-journal/${item.virtualGroupId}`}>Журнал</Button>

                                                                                    {isAdmin
                                                                                    ? <Button startIcon={<EditIcon />}
                                                                                              href={`/edit-virtual-group/${item.virtualGroupId}`}>Редактировать</Button>
                                                                                    : <></>}
                                                                                    {isAdmin
                                                                                    ? <Button disabled={item.deleted} startIcon={<DeleteIcon />} color={'error'} onClick={() => removeVirtualGroup(item)}>Удалить</Button>
                                                                                    : <></>}
                                                                                </ButtonGroup>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                    </>
                                                })
                                                }
                                            </Stack>
                                        </TabPanel>
                                    </TabContext>
                                </CardContent>
                            </Card>
                            <br/>
                        </div>
                    </section>
                </main>
            </MainLayout>
        </>
    );
}
